import React, {} from "react"
import SearchPageContent from "../../components/search/search-page-content/search-page-content"
import styles from "./index.module.scss"
import SearchLayout from "../../components/layout/search-layout/search-layout"
import auth from "../../services/auth"

const ViewMyUploads = props => {
  return (
    <SearchLayout location={props.location} searchPath="searchMine">
      <SearchPageContent heading="View My Media"></SearchPageContent>
    </SearchLayout>
  )
}

export default ViewMyUploads
